<template>
  <Anonymous>
    <h1>Page not found</h1>
  </Anonymous>
</template>

<script>
import Anonymous from "../layouts/Anonymous";

export default {
  name: "PageNotFound",
  components: {Anonymous}
}
</script>

<style scoped>

</style>