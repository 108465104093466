<template>
  <div class="container-fluid px-0 anonymous h-100">
    <div class="row mx-0 h-100">
      <div class="col-md-6 d-flex align-items-center justify-content-center">
        <slot></slot>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-center right-side login-right-side">


      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Anonymous",
  components: {
  },
}

</script>

<style scoped>

</style>